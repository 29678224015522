import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Javascript ES6 … 3 dots 101",
  "author": "tzookb",
  "type": "post",
  "date": "2018-06-21T20:39:52.000Z",
  "url": "/2018/06/javascript-es6-3-dots-101/",
  "categories": ["javascript"],
  "tags": ["es6", "functional", "javascript", "programing", "tips"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`ES6 3 dots syntax has a lot of awesome tricks to do a lot of tricks and variable manipulations.`}</p>
    <p>{`As it can save you many lines of code it will help you with your functional programing as it doesn`}{`’`}{`t mutate the `}{`“`}{`changed`}{`”`}{` variable.`}</p>
    <h3>{`Javascript actions without mutating`}</h3>
    <h4>{`extract a key from object`}</h4>
    <pre><code parentName="pre" {...{}}>{`const obj = {a:1, b:2, c:3};
let {c} = obj;
console.log(c); //3
`}</code></pre>
    <h4>{`remove keys from object without mutating`}</h4>
    <pre><code parentName="pre" {...{}}>{`let {b,...rest} = obj;
console.log(obj); // {a:1, b:2, c:3}
console.log(rest); // {a:1, c:3}
`}</code></pre>
    <h4>{`add key to object`}</h4>
    <pre><code parentName="pre" {...{}}>{`const added_d_key = {...obj, d: 4};
console.log(obj); // {a:1, b:2, c:3}
console.log(added_d_key); // {a:1, b:2, c:3, d:4}
`}</code></pre>
    <h4>{`update key in object`}</h4>
    <pre><code parentName="pre" {...{}}>{`const update_c_key = {...obj, c: 100};
console.log(obj); // {a:1, b:2, c:3}
console.log(update_c_key); // {a:1, b:2, c:100}
`}</code></pre>
    <h4>{`array tricks`}</h4>
    <pre><code parentName="pre" {...{}}>{`const arr = [1,2,3];

 add element to array
const arr_added = [...arr, 4];
console.log(arr);
console.log(arr_added);

`}</code></pre>
    <h4>{`remove element from array`}</h4>
    <pre><code parentName="pre" {...{}}>{`const indexToRemove = 1;
const part_arr = [
    ...arr.slice(0, indexToRemove),
    ...arr.slice(indexToRemove + 1)
];
console.log(arr);
console.log(part_arr);

`}</code></pre>
    <h4>{`function params unspread`}</h4>
    <pre><code parentName="pre" {...{}}>{`function destructObj({a, b}) {
    console.log(a, b); // 1 2
}
destructObj(obj);

`}</code></pre>
    <h4>{`function params “join”`}</h4>
    <pre><code parentName="pre" {...{}}>{`function join_args(...joined_args) {
    console.log(joined_args); // [ 1, 'a', 2, 'b' ]
}
join_args(1,'a',2,'b');

`}</code></pre>
    <h4>{`break array items to function variable`}</h4>
    <pre><code parentName="pre" {...{}}>{`function break_array_to_func_vars(a, b) {
console.log(a, b); // 1 2
}
break_array_to_func_vars(...[1,2]);

`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      